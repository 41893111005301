import React, { useEffect, useRef } from 'react';
import HeroSection from '../../../components/organism/heroSection/index.tsx';
import Navbar from '../../../components/organism/navbar/index.tsx';
import './index.css'
import SendMessage from '../../../components/organism/sendMessage/index.tsx';
import Footer from '../../../components/organism/footer/index.tsx';
import InfoContent from '../../../components/organism/infoContent/index.tsx';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store.ts';
import { ScrollTo } from '../../../hooks/scrollTo.ts';
import { SlideIn } from '../../../hooks/slideIn.ts';

const Portfolio : React.FC = () => { 
  const  {loaderShown} = useSelector((state: RootState) => state)

  const contactRef = useRef<HTMLDivElement>(null)
  const elementsInView = useRef<HTMLDivElement[]>([]);

  ScrollTo({top: 0, behaviour: 'instant'})
  SlideIn({elementsInView: elementsInView})

   const scrollToContact = () => {
    if(contactRef.current !== null) {
      contactRef.current.scrollIntoView(false)
    }
  }    
  
  useEffect(() => {
    if(loaderShown) {
      document.querySelector('.body-container')?.classList.add('position-initial')
    }
  }, [])

  return (
    <div className='body-container'>
      <Navbar scrollToContact={scrollToContact} />
      <HeroSection 
        isHome={false} 
        title='Portfolio' 
        subtitle={`Naš portfolio predstavlja širok spektar naših digitalnih ostvarenja, od web stranica do mobilnih aplikacija i grafičkog dizajna. Pregledajte naše radove kako biste dobili uvid u našu stručnost, kreativnost i sposobnost da vaše ideje pretvorimo u stvarnost.`} />


      <InfoContent 
        ref={(el: HTMLDivElement) => elementsInView.current[0] = el}
        className='offset'
        // onClick={() => {
        //   // window.open('https://play.google.com/store/apps/details?id=com.hzppapp')
        // }}
        isProject={true}
        imageUrl={require('../../../assets/wedding-camera.png')} 
        imageLeft={true}
        title="Wedding camera"
        subtitle={[`Wedding Camera mobilna je aplikacija koja modernizira način na koji gosti bilježe i dijele uspomene na vjenčanjima. S vlastitim pametnim telefonima, gosti jednostavno fotografiraju i prenose slike u zajedničku galeriju, stvarajući nezaboravan kolektivni album.`, <br/>, `Idealno je rješenje za mladence koji žele jednostavan, moderan i zabavan način prikupljanja autentičnih trenutaka svog velikog dana.`, <br/>, <br/>,`Posjetite `, <a href='https://weddingcamera.app' target='_blank' referrerPolicy='no-referrer' rel="noreferrer">weddingcamera.app</a>,` i otkrijte kako vaši gosti mogu postati fotografi na vašem vjenčanju!` ]} />

      <InfoContent 
        ref={(el: HTMLDivElement) => elementsInView.current[1] = el}
        className='offset'
        // onClick={() => {
        //   // window.open('https://play.google.com/store/apps/details?id=com.hzppapp')
        // }}
        isProject={true}
        imageUrl={require('../../../assets/raspored-vlakova-work.png')} 
        imageLeft={false}
        title="Raspored vlakova Hrvatske"
        subtitle={['Raspored vlakova Hrvatske je sveobuhvatna aplikacija za praćenje voznog reda, informacije o kašnjenjima, detalje o presjedanjima i brzu kupnju karata. Stvorena je kako bi vam olakšala putovanje vlakom, pružajući sve potrebne informacije na dlanu bez obzira putujete li na posao, obiteljski izlet ili avanturu.', <br/>, 'Dostupna na ',<a href='https://play.google.com/store/apps/details?id=com.hzppapp' target='_blank' rel="noreferrer" >Android</a>, ' i na ',<a href='https://apps.apple.com/us/app/raspored-vlakova-hrvatske/id6504168712' target='_blank' rel="noreferrer" >iOS</a>, ' uređajima.']} />
      
      <InfoContent 
        ref={(el: HTMLDivElement) => elementsInView.current[2] = el}
        className='offset'
        isProject={true}
        imageUrl={require('../../../assets/travel-booking-project.png')} 
        imageLeft={true}
        title="UX dizajn - Travel booking"
        subtitle={'U današnjem svijetu, gdje su putovanja postala dio svakodnevnog života, važnost intuitivne i efikasne platforme za bukiranje putovanja nikada nije bila veća. Naš projekt, responzivna web stranica za pronalazak i bukiranje putovanja, dizajnirana je s ciljem pružanja besprijekornog korisničkog iskustva, omogućavajući korisnicima da lako pretražuju, uspoređuju i rezerviraju putovanja s bilo kojeg uređaja.'} />
      
      <InfoContent 
        ref={(el: HTMLDivElement) => elementsInView.current[3] = el}
        className='offset'
        isProject={true}
        isLast={true}
        imageUrl={require('../../../assets/job-search-project.png')} 
        imageLeft={false}
        title="UX dizajn - Job search platforma"
        subtitle={'Platforma je dizajnirana s naglaskom na jednostavnost i navigacijsku jasnoću, osiguravajući da korisnici mogu lako pristupiti svim potrebnim funkcionalnostima. Uvjereni smo da smo kroz ovaj UX dizajn uspjeli postići ravnotežu između funkcionalnosti i estetike, čime bismo olakšali put korisnicima do njihovog idealnog posla.'} />


      <SendMessage 
        className='offset' 
        lang='hr'
        ref={(el: HTMLDivElement) => elementsInView.current[4] = el}
      />

      <Footer
        className='offset' 
        ref={(el: HTMLDivElement) => {
          elementsInView.current[5] = el
          contactRef.current = el
        }}/>

    </div>
  )
}

export default Portfolio
import React, { useEffect, useRef } from 'react';
import HeroSection from '../../../components/organism/heroSection/index.tsx';
import Navbar from '../../../components/organism/navbar/index.tsx';
import './index.css'
import SendMessage from '../../../components/organism/sendMessage/index.tsx';
import Footer from '../../../components/organism/footer/index.tsx';
import InfoContent from '../../../components/organism/infoContent/index.tsx';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store.ts';
import { ScrollTo } from '../../../hooks/scrollTo.ts';
import { SlideIn } from '../../../hooks/slideIn.ts';

const Portfolio : React.FC = () => { 
  const  {loaderShown} = useSelector((state: RootState) => state)
  
  const contactRef = useRef<HTMLDivElement>(null)
  const elementsInView = useRef<HTMLDivElement[]>([]);


  ScrollTo({top: 0, behaviour: 'instant'})
  SlideIn({elementsInView: elementsInView})

   const scrollToContact = () => {
    if(contactRef.current !== null) {
      contactRef.current.scrollIntoView(false)
    }
  }    
  
  useEffect(() => {
    if(loaderShown) {
      document.querySelector('.body-container')?.classList.add('position-initial')
    }
  }, [])

  return (
    <div className='body-container'>
      <Navbar routePrefix={'/en'} scrollToContact={scrollToContact} />
      <HeroSection 
        isHome={false} 
        title='Portfolio' 
        subtitle={`Our portfolio showcases a wide range of our digital achievements, from websites to mobile applications and graphic design. Browse through our work to gain insight into our expertise, creativity, and ability to turn your ideas into reality.`} />

      <InfoContent 
        ref={(el: HTMLDivElement) => elementsInView.current[0] = el}
        className='offset'
        // onClick={() => {
        //   // window.open('https://play.google.com/store/apps/details?id=com.hzppapp')
        // }}
        isProject={true}
        imageUrl={require('../../../assets/wedding-camera.png')} 
        imageLeft={true}
        title="Wedding camera"
        subtitle={[`Wedding Camera is a mobile app that modernizes how guests capture and share memories at weddings. Using their smartphones, guests can easily take photos and upload them to a shared gallery, creating an unforgettable collective album.`, <br/>, `It is the perfect solution for couples who want a simple, modern, and fun way to gather authentic moments from their special day.`, <br/>, <br/>,`Visit `, <a href='https://weddingcamera.app' target='_blank' referrerPolicy='no-referrer' rel="noreferrer">weddingcamera.app</a>,` and discover how your guests can become photographers at your wedding!` ]} />


      <InfoContent 
        ref={(el: HTMLDivElement) => elementsInView.current[1] = el}
        className='offset'
        isProject={true}
        imageUrl={require('../../../assets/raspored-vlakova-work.png')} 
        imageLeft={false}
        title="Raspored vlakova Hrvatske"
        subtitle={['Raspored vlakova Hrvatske (Croatian Railway schedule) is a comprehensive application for tracking train schedules, information on delays, details about transfers, and quick ticket purchase. It is created to make your train travel easier, providing all the necessary information at your fingertips, whether you are traveling for work, a family outing, or an adventure.', <br/>, "Available on ",<a href='https://play.google.com/store/apps/details?id=com.hzppapp' target='_blank' rel="noreferrer" >Android</a>, ' and ',<a href='https://apps.apple.com/us/app/raspored-vlakova-hrvatske/id6504168712' target='_blank' rel="noreferrer" >iOS</a>, ' devices.']} />

      <InfoContent 
        ref={(el: HTMLDivElement) => elementsInView.current[2] = el}
        className='offset'
        isProject={true}
        imageUrl={require('../../../assets/travel-booking-project.png')} 
        imageLeft={true}
        title="UX design - Travel booking"
        subtitle={"In today's world, where travel has become a part of everyday life, the importance of an intuitive and efficient travel booking platform has never been greater. Our project, a responsive website for finding and booking travel, is designed with the aim of providing a seamless user experience, allowing users to easily search, compare, and book trips from any device."} />
      
      <InfoContent 
        ref={(el: HTMLDivElement) => elementsInView.current[3] = el}
        className='offset'
        isProject={true}
        isLast={true}
        imageUrl={require('../../../assets/job-search-project.png')} 
        imageLeft={false}
        title="UX design - Job search platform"
        subtitle={"Web platform designed with an emphasis on simplicity and navigational clarity, ensuring that users can easily access all the necessary functionalities. We are convinced that through this UX design, we managed to achieve a balance between functionality and aesthetics, which would make it easier for users to find their ideal job."} />

      <SendMessage 
        className='offset' 
        ref={(el: HTMLDivElement) => elementsInView.current[4] = el}
        lang='en' />

      <Footer 
        className='offset' 
        lang='en' 
        ref={(el: HTMLDivElement) => {
          elementsInView.current[5] = el
          contactRef.current = el
        }}/>
        
    </div>
  )
}

export default Portfolio